<template>
  <footer class="bg-blue text-white text-left footer">
    <div class="container px-md-0">
      <div class="row d-flex justify-content-center">
        <div class="col-md-4 mb-4 mb-md-0">
          <b-img
            class="logo mb-2"
            alt="teddi-white"
            :src="require('@/assets/logo/teddi-white.svg')"
          ></b-img>
          <div class="small-text mb-2">Recevez les annonces immobilières en premier</div>
          <ul class="opacity-low">
            <li>
              <b-link to="/privacy" @click.native="$scrollToTop"
                >Politique de confidentialité</b-link
              >
            </li>
            <li>
              <b-link to="/terms" @click.native="$scrollToTop">CGUV</b-link>
            </li>
            <li>Paiement sécurisé</li>
          </ul>
          <div class="d-flex flex-row opacity-low">
            <IconVisa class="mr-2" />
            <IconMastercard class="mr-2" />
            <IconCreditCard />
          </div>
        </div>
        <div class="col-md-8">
          <div class="row links">
            <div class="col-md-4">
              <h5>À propos</h5>
              <ul>
                <li>
                  <b-link to="/blog" @click.native="$scrollToTop">Blog</b-link>
                </li>                
                <li>
                  <a href="mailto:hello@teddi.co">Nous contacter</a>
                </li>
                <!-- <li>Presse</li> -->
                <!-- <li>Recrutement</li> -->
              </ul>
            </div>
            <div class="col-md-4">
              <h5>Services</h5>
              <ul>
                <li>
                  <b-link :to="{ name: 'timeline-buy' }" @click.native="$scrollToTop"
                    >Achat immobilier</b-link
                  >
                </li>
                <li>
                  <b-link :to="{ name: 'timeline-rent' }" @click.native="$scrollToTop"
                    >Location immobilière</b-link
                  >
                </li>
                <!--  <li>Assurance habitation</li> -->
                <!--  <li>Travaux</li>
                <li>Déménagement</li>
                <li>Gestion locative</li> -->
              </ul>
            </div>
            <div class="col-md-4">
              <h5>Suivez-nous</h5>
              <ul>
                <li>
                  <a target="_blank" rel="noopener" href="https://www.facebook.com/www.teddi.co/"
                    >Facebook</a
                  >
                </li>
                <li>
                  <a target="_blank" rel="noopener" href="https://www.instagram.com/teddi.co"
                    >Instagram</a
                  >
                </li>
                <li>
                  <a target="_blank" rel="noopener" href="https://www.pinterest.fr/teddi_co/"
                    >Pinterest</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import IconVisa from "@/components/icons/IconVisa.vue";
import IconCreditCard from "@/components/icons/IconCreditCard.vue";
import IconMastercard from "@/components/icons/IconMastercard.vue";
export default {
  components: {
    IconMastercard,
    IconVisa,
    IconCreditCard,
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
footer {
  a {
    text-decoration: none;
    color: inherit;
  }
  h5 {
    font-weight: bold;
  }
  padding: 2rem 1rem 2rem 1rem;
  @include media-breakpoint-up(md) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  ul {
    padding-left: 0;
    li {
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }
  }
  .small-text {
    font-size: 0.8rem;
  }

  .links {
    li {
      opacity: 0.7;
    }
  }
}
</style>
<style lang="scss" scoped>
::v-deep.footer {
  svg {
    height: 2.5rem;
    width: 2.5rem;
    display: block;
  }
}
::v-deep.footer svg {
  path {
    fill: white !important;
  }
}
</style>
